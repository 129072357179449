<template>
  <div id="divInvoicesWrapper">
    <div class="top-content-wrapper">
      <VueContentHolder :padding="['25', '20']">
        <div class="top-content-actions">
          <BrandButton
            :outlined="!isCurrentTab"
            :size="sizes.xSmall"
            :contentAlignment="constants.flexAlignment.center"
          >
            <VueText sizeLevel="4" weightLevel="4">Faturalarım</VueText>
          </BrandButton>
          <BrandButton
            :outlined="isCurrentTab"
            :size="sizes.xSmall"
            :contentAlignment="constants.flexAlignment.center"
            @click="goToArchive"
          >
            <VueText sizeLevel="4" weightLevel="4">E-Arşiv</VueText>
          </BrandButton>
        </div>
        <VueContentHolder class="people-wrapper">
          <VueGridContainer>
            <VueGridRow>
              <VueGridCol>
                <BrandDatePicker
                  class="input-item"
                  ref="startDatePicker"
                  :isDateDisabled="isStartDateDisabled"
                  v-model="startDate"
                  :displayFormat="'DD.MM.YYYY'"
                  :format="'YYYY-M-D'"
                >
                  <BrandMeetPerson
                    @click="toggleStartDatePicker"
                    :person="{
                      title: 'Başlangıç Tarihi',
                      name: parseISODate(startDate),
                    }"
                  />
                </BrandDatePicker>
              </VueGridCol>
              <VueGridCol>
                <BrandDatePicker
                  class="input-item"
                  ref="endDatePicker"
                  v-model="endDate"
                  :displayFormat="'DD.MM.YYYY'"
                  :isDateDisabled="isEndDateDisabled"
                  :format="'YYYY-M-D'"
                >
                  <BrandMeetPerson
                    @click="toggleEndDatePicker"
                    :person="{ title: 'Bitiş Tarihi', name: parseISODate(endDate) }"
                  />
                </BrandDatePicker>
              </VueGridCol>
            </VueGridRow>
          </VueGridContainer>
          <div v-if="dateValidation.validate && !dateValidation.valid" class="date-validation">
            {{ dateValidation.message }}
          </div>
        </VueContentHolder>
      </VueContentHolder>
    </div>
    <VueContentHolder :padding="['0']">
      <table>
        <tr class="table-header-row">
          <th>
            <VueText sizeLevel="3" weightLevel="2" color="grey-30">Fatura Tarihi</VueText>
          </th>
          <th>
            <VueText sizeLevel="3" weightLevel="2" color="grey-30">Fatura No</VueText>
          </th>
          <th>
            <VueText sizeLevel="3" weightLevel="2" color="grey-30">Fatura Tutarı</VueText>
          </th>
        </tr>
        <tr v-if="!invoices || invoices.length == 0" class="nodata-warning">
          <td colspan="3">Seçtiğiniz tarih aralığında fatura bulunmuyor.</td>
        </tr>
        <tr v-for="invoice in invoices" :key="invoice.invoiceNumber">
          <td class="table-data">
            <VueText sizeLevel="2" weightLevel="2" color="grey-30">{{
              invoice.invoiceDate
            }}</VueText>
          </td>
          <td class="table-data">
            <VueText sizeLevel="2" weightLevel="2" color="grey-20">{{
              invoice.invoiceNumber
            }}</VueText>
          </td>
          <td id="invoice-amount" class="table-data">
            <VueText sizeLevel="2" weightLevel="4" color="grey-50"
              >{{ invoice.invoiceAmount | currencyFormat }} TL</VueText
            >
          </td>
        </tr>
      </table>

      <div class="totalAmount">
        <table v-if="invoices.length > 0">
          <tr>
            <td>Toplam Fatura Adeti:</td>
            <td>{{ invoices.length }}</td>
          </tr>
          <tr>
            <td>Toplam Fatura Tutarı:</td>
            <td>{{ invoices.reduce((a, b) => a + b.invoiceAmount, 0) | currencyFormat }} TL</td>
          </tr>
        </table>
      </div>
    </VueContentHolder>
  </div>
</template>

<script>
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import BrandMeetPerson from '@/components/brand/BrandMeetPerson/BrandMeetPerson.vue';
import VueGridContainer from '@/components/shared/VueGridContainer/VueGridContainer.vue';
import VueGridRow from '@/components/shared/VueGridRow/VueGridRow.vue';
import VueGridCol from '@/components/shared/VueGridCol/VueGridCol.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import { COMPONENT_SIZES } from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import { Finance } from '@/services/Api/index';
import RoutesRoot from '@/router/routes/RoutesRoot.js';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesBusinessPartners from '@/router/routes/secure/RoutesBusinessPartners.js';
import RoutesPmi from '@/router/routes/secure/businessPartners/RoutesPmi.js';
import currencyFilter from '@/mixins/currencyFilter.js';
import dateUtils from '@/mixins/dateUtils.js';
import BrandDatePicker from '@/components/brand/BrandDatePicker/BrandDatePicker.vue';

export default {
  name: 'Bills',
  components: {
    BrandButton,
    VueContentHolder,
    VueGridContainer,
    VueGridRow,
    VueGridCol,
    BrandMeetPerson,
    VueText,
    BrandDatePicker,
  },
  mixins: [currencyFilter, dateUtils],
  data() {
    let currentDate = new Date();
    let start = `${
      currentDate.getMonth() === 0 ? currentDate.getFullYear() - 1 : currentDate.getFullYear()
    }-${currentDate.getMonth() === 0 ? 12 : currentDate.getMonth()}-${currentDate.getDate()}`;
    let end = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
    return {
      startDate: start,
      endDate: end,
      dateValidation: {
        valid: true,
        validate: false,
        message: 'Tarih aralığı 1 aydan fazla olmamalı',
      },
      selectedInvoiceNumber: '',
      selectedInvoiceByteArray: '',
      renderKey: 1,
      invoices: [],
      isCurrentTab: true,
    };
  },
  computed: {
    sizes() {
      return COMPONENT_SIZES;
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    icons() {
      return COMPONENT_CONSTANTS.ICON_VARIABLES;
    },
  },
  watch: {
    startDate: {
      handler: function() {
        this.getInvoices();
      },
    },
    endDate: {
      handler: function() {
        this.getInvoices();
      },
    },
  },
  filters: {
    currencyFormat(value) {
      value = new Intl.NumberFormat('de-DE').format(value);
      return value;
    },
  },
  methods: {
    isStartDateDisabled(date) {
      const currentDate = new Date();
      // currentDate.setMonth(currentDate.getMonth() - 1);
      return date > currentDate || date.getFullYear() < 2007;
    },
    isEndDateDisabled(date) {
      const currentDate = new Date();
      return date > currentDate || date.getFullYear() < 2007;
    },
    toggleStartDatePicker() {
      this.$refs.startDatePicker.$children[0].toggle();
    },
    toggleEndDatePicker() {
      this.$refs.endDatePicker.$children[0].toggle();
    },
    validateDate() {
      this.$set(this.dateValidation, 'validate', true);
      let arrStart = this.startDate.split('-');
      let arrEnd = this.endDate.split('-');

      let dayStart = new Date(arrStart[0], arrStart[1] - 1, arrStart[2]);
      let dayEnd = new Date(arrEnd[0], arrEnd[1] - 1, arrEnd[2]);

      // To calculate the time difference of two dates
      var Difference_In_Time = dayEnd.getTime() - dayStart.getTime();

      // To calculate the no. of days between two dates
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      if (Difference_In_Days > 31) {
        this.$set(this.dateValidation, 'valid', false);
        this.$set(this.dateValidation, 'message', 'Tarih aralığı bir aydan fazla olmamalı');
      } else if (dayEnd < dayStart) {
        this.$set(this.dateValidation, 'valid', false);
        this.$set(this.dateValidation, 'message', 'Bitiş tarihi başlangıçtan büyük olmalı');
      } else {
        this.$set(this.dateValidation, 'valid', true);
        this.$set(this.dateValidation, 'message', '');
      }
      return this.dateValidation.valid;
    },
    goToArchive() {
      this.$router.push(
        `${RoutesRoot.Secure.path}/${RoutesSecure.BusinessPartners.path}/${RoutesBusinessPartners.Pmi.path}/${RoutesPmi.Archive.path}`,
      );
    },
    getInvoices() {
      if (this.validateDate()) {
        this.$set(this.dateValidation, 'validate', false);

        Finance.getInvoices(this.startDate, this.endDate).then(res => {
          const {
            Data: { invoces },
          } = res.data;

          this.invoices = invoces;
        });
      }
    },
    setBottomBarStatus(status) {
      this.$store.dispatch('app/setBottomBarStatus', status);
    },
  },
};
</script>

<style scoped lang="scss">
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateY(100%);
}
.top-content-wrapper {
  padding: 0;
  background-color: palette-color-level('grey', '10');
  border-bottom: 1px solid palette-color-level('grey', '20');
  margin-bottom: palette-space-level('20');
  .people-wrapper {
    .date-validation {
      color: palette-color-level('red', 30);
      padding-left: 2px;
      padding-top: 5px;
      font-size: 13px;
    }
    .input-item {
      width: 100%;
    }
  }
}
.top-content-actions {
  display: flex;
  align-items: space-between;
  padding-bottom: palette-space-level('20');
  z-index: 5;

  button:not(:first-child) {
    margin-left: palette-space-level(10);
  }
  button:not(:last-child) {
    margin-right: palette-space-level(10);
  }
}
table {
  width: 100%;
  border-top: 1px solid palette-color-level('grey', '20');
  border-bottom: 1px solid palette-color-level('grey', '20');
  margin-bottom: palette-space-level('20');

  .nodata-warning {
    td {
      background-color: #f8f9f9;
      color: #e69300;
    }
  }
  tr,
  td,
  th {
    padding: palette-space-level(15);
  }

  th:last-child,
  td:last-child {
    text-align: center;
  }

  tr:not(:last-child) {
    border-bottom: 1px solid palette-color-level('grey', '20');
  }
  td,
  th {
    border-right: 1px solid palette-color-level('grey', '20');
  }

  td#invoice-amount,
  th:nth-child(3) {
    text-align: right;
  }
}
.table-header-row {
  background-color: palette-color-level('grey', '10');
  padding: palette-space-level(25) 0;
}

td.table-data {
  padding: palette-space-level('10') !important;
}

.totalAmount {
  display: block;
  margin: auto;
  min-width: 218px;
  font-size: 11px;
  color: #79838e;

  table {
    background-color: #f8f9f9;
    border-left: none;
    border-right: none;
  }

  table > tr > td {
    text-align: left;
    border: none;

    &:first-child {
      width: 163px;
    }

    &:last-child {
      padding-left: 0;
    }
  }
}
</style>
